import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core"
import React from "react"
import { Layout, SEO } from "../components"
import { makeStyles, useTheme } from "@material-ui/core/styles"

import { useStaticQuery, graphql } from "gatsby"
import { useWindowSize } from "../utils"

const useStyles = makeStyles({
  card: {
    margin: 6,
    minHeight: "230px",
    background:
      "linear-gradient( 159deg,rgba(37,37,50,0.98) 0%,rgba(35,35,45,0.98) 100% )",
    color: "white",
    boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
    padding: "5px",
  },
  hr: {
    backgroundColor: "white",
  },
})

const myStory = () => {
  const classes = useStyles()
  const { personal } = useStaticQuery(
    graphql`
      query MyStory {
        personal: allContentfulPersonalInformation {
          edges {
            node {
              id
              name
              story {
                story
              }
            }
          }
        }
      }
    `
  )

  const story = personal.edges[0]

  const { width } = useWindowSize()
  return (
    <Layout title={"My Story"}>
      <SEO title="My Story" previewImage={{ width: 267 }} />
      <Box mt={2} ml={3} mr={3}>
        <Box>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      style={{
                        fontSize:
                          width > 2100
                            ? "40px"
                            : width > 1024
                            ? "30px"
                            : width > 425
                            ? "24px"
                            : "18px",
                      }}
                      component="h2"
                    >
                      Hi! I'm {story.node.name} - aka JAZZ 👋.
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        fontSize:
                          width > 2100
                            ? "30px"
                            : width > 1024
                            ? "20px"
                            : width > 425
                            ? "19px"
                            : "16px",
                      }}
                      variant="h6"
                      component="h3"
                    >
                      I'm a Coder, Gamer, Student, Developer and sometimes a
                      Teacher ! 😎😎
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{
                        fontSize:
                          width > 2100
                            ? "20px"
                            : width > 1024
                            ? "18px"
                            : width > 425
                            ? "17px"
                            : "14px",
                      }}
                      component="h6"
                    >
                      {story.node.story.story}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  )
}

export default myStory
